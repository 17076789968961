import Navigation from '@naturehouse/design-system/components/organisms/navigation/Navigation';

export default class NavigationHelper {
    static #instance: NavigationHelper | null = null;

    readonly #observer: IntersectionObserver | null = null;

    readonly #navigation = document.querySelector('nh-navigation') as Navigation;

    readonly #heroContainer = document.querySelector('[data-role="hero-container"]') as HTMLElement;

    public constructor() {
        if (this.#navigation === null || this.#heroContainer === null) {
            throw new Error('NavigationHelper is not setup correctly');
        }

        if ('IntersectionObserver' in window) {
            this.#observer = new IntersectionObserver(this.intersectionObserverCallback, {
                rootMargin: '0px 0px 200px 0px',
                threshold: 0.95
            });

            this.#observer.observe(this.#heroContainer);
            return;
        }

        this.#navigation.transparent = false;
    }

    public static getInstance(): NavigationHelper {
        if (this.#instance === null) {
            this.#instance = new NavigationHelper();
        }

        return this.#instance;
    }

    public static resetInstance(): NavigationHelper {
        this.#instance = new NavigationHelper();
        return this.#instance;
    }

    public intersectionObserverCallback = (entries: IntersectionObserverEntry[]): void => {
        const leavesViewport = entries.filter((entry) => !entry.isIntersecting).length > 0;
        this.#navigation.transparent = !leavesViewport;
    };
}
